body {
  margin: auto !important;
  width: 300px !important;
}
button:disabled,
input[type='button']:disabled,
input[type='reset']:disabled,
input[type='submit']:disabled {
  opacity: 0.3 !important;
}
